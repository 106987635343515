import React, { useState, useEffect } from "react";
import { TenorGif } from "./types";
import { useTenorClient } from "./TenorClientContext";
// pass the keyword to the component
// must be used inside of a ClientContext

interface Props {
  keyword: string;
  countPerFetch?: number;
}

export const InfiniteRandomGifs = ({ keyword, countPerFetch = 10 }: Props) => {
  // array of media fetched from this page
  // fetch 10 at a time in order to minimize requests
  const [media, setMedia] = useState<TenorGif[]>([]);

  // indexes for the current position in the media array
  const [currentIndex, setCurrentIndex] = useState(0);

  // TS assumes this will always be defined, but in reality it won't be
  const currentImage = media[currentIndex];

  // access Tenor client from context
  const client = useTenorClient();

  // run effect to fetch more images when the currentIndex is
  // out of the bounds of the array
  useEffect(() => {
    let isMounted = true;

    const loadMore = async () => {
      try {
        const images = await client.random(keyword, countPerFetch);
        if (isMounted) {
          setMedia(images);
          setCurrentIndex(0);
        }
      } catch (e) {
        console.error(e);
      }
    };

    if (currentIndex >= media.length) {
      loadMore();
    }

    return () => {
      isMounted = false;
    };
  }, [currentIndex, media.length, keyword]); //TODO: refresh on keyword change

  return (
    <div className="App">
      {currentImage ? (
        <div>
          <div>
            <a href={currentImage.url}>
              <img id="cat" src={currentImage.media[0]?.gif?.url} alt="cat gif" />
            </a>
            <button onClick={() => setCurrentIndex((i) => i + 1)}>
              Хочу другого котика
            </button>
          </div>
        </div>
      ) : (
        <div>...</div>
      )}
    </div>
  );
};

import React from "react";
import "./styles.css";
import { InfiniteRandomGifs } from "./InfiniteRandomGifs";
import { TenorProvider } from "./TenorClientContext";

const API_KEY = "LIVDSRZULELA"; // example key from the docs

export default function App() {
  return (
    <TenorProvider apiKey={API_KEY}>
      <div className="background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <link rel="preconnect" href="https://rsms.me/"/>
      <link rel="stylesheet" href="https://rsms.me/inter/inter.css"/>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
              {/* <img src="./ustate-logo.jpg" id="logo"/> */}
        <div style={{ display: "flex", alignItems: "center"}}>
          <InfiniteRandomGifs keyword={"cat"} />
          <div>
          <span>
            Сайт в разработке. Пока что можете поглядеть на котиков. Либо вы
            можете перейти на наши другие платформы:
          </span>
          <div style={{textAlign:"center", verticalAlign:"middle"}}>
          <a href="https://discord.gg/3WS4tU5"><img src="./discord-logo.svg" id="discord"/></a>
          <a href="https://vk.com/ustate.link"><img src="./vk-logo.svg" id="vk"/></a>
          </div>
          </div>
        </div>
      </div>
    </TenorProvider>
  );
}

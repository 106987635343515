import { TenorGif } from "./types";

export class TenorClient {
  constructor(private key: string) {}
  
  async random(keyword: string, count: number = 10): Promise<TenorGif[]> {
    const url = `https://api.tenor.com/v1/random?q=${encodeURIComponent(
      keyword
    )}&key=${this.key}&limit=${count}`;

    const response = await fetch(url);

    const json = (await response.json()) as { results?: TenorGif[] };

    const images = json.results;

    if (!images) {
      console.log(response, json, this.key);
      throw new Error("Invalid Response");
    }

    return images;
  }
}

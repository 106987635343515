import React, { createContext, useContext, useMemo } from "react";
import { TenorClient } from "./TenorClient";

// create a single client to be available throughout the app

const ClientContext = createContext(new TenorClient(""));

export const TenorProvider: React.FC<{ apiKey: string, children?: React.ReactNode }> = ({
  apiKey,
  children
}) => {
  // only create once, unless key changes
  const client = useMemo(() => new TenorClient(apiKey), [apiKey]);

  return (
    <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
  );
};

export const useTenorClient = () => useContext(ClientContext);
